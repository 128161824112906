<template>
	<div>
		<base-table :headers="headers" :items="items" table-top paginate>
			<template #cell(CT_SDATE)="{ item }">
				<span>{{ new Date(item.CT_SDATE).toLocaleDateString() }}</span>
			</template>
			<template #cell(progress)="{ item }">
				<strong>{{ item.progress }}%</strong>
			</template>
			<template #cell(CT_PRICEUSD)="{ item }">
				<span>${{ item.CT_PRICEUSD }}</span>
			</template>
		</base-table>
	</div>
</template>

<script>
import BaseTable from '@/components/BaseTable.vue';
import api from '@/utils/api';

export default {
	name: 'Progress',

	components: { BaseTable },

	data: () => ({
		items: [],

		headers: [
			{ key: 'CT_PROJID', label: 'ID' },
			{ key: 'SP_TITLE', label: 'Name' },
			{ key: 'CT_PRICEUSD', label: 'Cost' },
			{ key: 'CT_FINALCOST', label: 'Final Cost' },
			{ key: 'CT_SDATE', label: 'Contract date' },
			{ key: 'CT_SITE_DELDATE', label: 'Delivery date' },
			{ key: 'CT_EDATE', label: 'End date' },
			{ key: 'CONSULTANT', label: 'Consultant' },
			{ key: 'CC_NAME', label: 'Contractor' },
			{ key: 'progress', label: 'progress' },
		],
	}),

	created() {
		this.loadData();
	},

	methods: {
		async loadData() {
			const { data } = await api.call({ path: 'reports/progress', method: 'GET' });
			this.items = data;
		},
	},
};
</script>

<style lang="scss" scoped></style>
